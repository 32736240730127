import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getLastUrl, getUser, isAuthenticated, logout, removeLastUrl, setLastUrl, signIn} from "../../auth/Auth";
import {connection, generateOtp, getCallback} from "../../services/AuthServices";

function SocialMediaRedirection() {

  useEffect(() => {

    const handleRedirect = async () => {

        const urlParams = new URLSearchParams(window.location.search);
        const mail = urlParams.get('email');
        //let mail = localStorage.getItem('SocialLoginEmail');

        console.log(mail);

      // setLoading(true);
      const user = await getCallback(mail);

      console.log(user);
      //handeling the response status and message

        //storing data in the localstorage
        signIn(user.data);

        const response = await generateOtp(getUser().id);
        const otp = response.data.otp.otp;
        if (otp) {
            window.location.replace(process.env.REACT_APP_CLIENT_SERVICE+ "?otp=" + otp);

          //window.location.assign("http://" + lastUrl + "?otp=" + otp);
        }

    }
    handleRedirect();

  }, []);

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
         <h1>Redirection to LogIn</h1>
        </div>
      </div>
    </section>
  );
}

export default SocialMediaRedirection;
