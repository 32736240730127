import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { useNavigate  } from 'react-router-dom';
import PasswordChecklist from "react-password-checklist";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {connection, generateOtp, registration} from "../../services/AuthServices";
import {getLastUrl, getUser, signIn} from "../../auth/Auth";
import {EMAIL_REGEX, PASSWORD_REGEX} from "../../Constants";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignUp() {
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [is_checked, setIsChecked] = useState(false);
  const { t, i18n } = useTranslation();

  const [success, setSuccess] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [password, setPassword] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [password_confirmation, setPasswordConfirmation] = useState("");
  const [validate, setValidate] = useState(true);
  const [checkPassword, setCheckPassword] = useState(false);
  const [checkPasswordConfirmation, setCheckPasswordConfirmation] = useState(false);
  const [isValid, setIsValid]= useState(false)
  const [isValid2, setIsValid2]= useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({
    email: "",
    firstName: "",
    lastName: "",
    password:"",
    password_confirmation:"",
    is_checked:""
  });
  const navigate = useNavigate();

  const resetErrors = () => {
    setErrors({
      email: "",
      firstName: "",
      lastName: "",
      password: "",
      password_confirmation: "",
      is_checked: ""
    });
  };

  const errorsRequired = t('Notifications.error.errorRequired');
  const errorsNbWord = t('Notifications.error.errorWrongValues');
  const errorNospasce = t('Notifications.error.errorNospace');
  const errorNospasce2 = t('Notifications.error.errorNospace');
  const errorsPasswordPattern = t('Notifications.error.errorsPasswordPattern'); 
  const errorEmail = t('Notifications.error.errorWrongEmail'); 
  const errorsCkecked = t('Notifications.error.errorTermsOfuse') 
  const errorsEqual = t('Notifications.error.errordiffPassword');

  let updatedErrors = {};
  const notify= (message, origin) => toast[origin](message);

  const handleFormChange = (e) => {
    resetErrors();
    const {name, value} = e.target;
    setValidate(false);
    updatedErrors = {};
    switch (name) {
      case "firstName":
          setFirstName(value);
        break;
      case "lastName":
        setLastName(value);
        break;
      case "phone":
        setPhone(value);
        break;
      case "company":
        setCompany(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "password":
        setPassword(value);
        value ==='' ? setCheckPassword(false) : setCheckPassword(true) 
        
        break;
      case "password_confirmation":
        setPasswordConfirmation(value);
        value ==='' ? setCheckPasswordConfirmation(false) : setCheckPasswordConfirmation(true) 
        break;
        case "checkbox_terms":
          setIsChecked(!is_checked);
        break;
      default:

    }

  };
  const formVerification = ()=>{
    let updatedErrors = {};

    if (firstName.trim() === "") {
      updatedErrors.firstName = errorsRequired;
    }

    if (lastName.trim() === "") {
      updatedErrors.lastName = errorsRequired;
    }

    if (email.trim() === "") {
      updatedErrors.email = errorsRequired;
    }else if(!email.match(EMAIL_REGEX)){
      updatedErrors.email = errorEmail;}

    if (password.trim() === "") {
      updatedErrors.password = errorsRequired;
    }else if(!password.trim().match(PASSWORD_REGEX)){
      updatedErrors.password = errorsPasswordPattern;
    }else if(password.includes(" ")){
      updatedErrors.password= errorNospasce

    }

    if (password_confirmation.trim() === "") {
      updatedErrors.password_confirmation = errorsRequired;
    }else if(!password_confirmation.trim().match(PASSWORD_REGEX)){
      updatedErrors.password_confirmation = errorsPasswordPattern;
    }else if (password_confirmation.includes(" ")) {
      updatedErrors.password_confirmation= errorNospasce
    }


    if(password_confirmation.trim() !== password.trim()){
      updatedErrors.password = errorsEqual;
      updatedErrors.password_confirmation = errorsEqual;
    }

    if (is_checked === false) {
      updatedErrors.is_checked = errorsCkecked;
    }

      setErrors({
        ...errors,
        ...updatedErrors,
      });

    setValidate(true);

    return Object.keys(updatedErrors).length === 0;
  }
  const register =  async (e) => {
    let cond = formVerification();
    setIsLoading(true);
    if (cond) {
      try {
        e.preventDefault();
        const data = {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          //username: email,
          phone: phone,
          about_me:company,
          password_confirmation: password_confirmation,
        }
        // setLoading(true);
        console.log(data)
        const response = await registration(data);

        if (response.success == true) {
          console.log(response)
          notify(response.message, 'success')
          signIn(response);
          const res = await generateOtp(getUser().id);
          console.log(res);
          const otp = res.data.otp.otp;
          if (cond == true) {
            console.log("Url validated");
            if (otp) {
              setAlertMessage(
                "Success! Your OTP has been sent to your email. Please check your email"
              );
              //lastUrl = 'http://localhost:3001'
              //location.replace(lastUrl + "?otp=" + otp);
              window.location.replace(process.env.REACT_APP_CLIENT_SERVICE + "?otp=" + otp);
              
              //return;
            }
          }
          //navigate('/login');
        }else{
          notify(t('Notifications.error.errorOccur'), 'error')

        }
        resetForm();

      } catch (error) {
        notify(t('Notifications.error.errorOccur'), 'error')
      }
    }
    setIsLoading(false);
  }


  const resetForm =()=>{
    setEmail("")
    setFirstName("")
    setLastName('')
    setPassword("")
    setPasswordConfirmation("")
    setPasswordConfirmation("")
    setIsChecked(false)
  }

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
        <div><ToastContainer /></div>
          <Tabs className="wd-form-login tf-tab">
            <img src='media-intelligence-icon.png' className="mb-4" alt="logo"/>
            <h4>{t('account.AccountInfo')}</h4>
            <div className="content-tab">
              <TabPanel className="inner animation-tab">
                <form action="/">
                  <div className="ip">
                    <label>
                    {t('account.Account_Fname')}<span>*</span>
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder={t('account.Account_Fname')}
                        value={firstName}
                        name="firstName"
                    />
                    {validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.firstName}</span> : ''}

                  </div>
                  <div className="ip">
                    <label>
                    {t('account.Account_Lname')}<span>*</span>
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder={t('account.Account_Lname')}
                        name="lastName"
                        value={lastName}
                    />
                    {validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.lastName}</span> : ''}
                  </div>
                  <div className="ip">
                    <label>
                    {t('account.AccountEmail')}<span>*</span>
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder={t('account.AccountEmail')}
                        name="email"
                        value={email}
                    />
                    {validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.email}</span>  : ''}
                  </div>
                  <div className="ip">
                    <label>
                    {t('account.AccountPhone')}
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder={t('account.AccountPhone')}
                        name="phone"
                        value={phone}
                    />
                  </div>
                  <div className="ip">
                    <label>
                    {t('account.AccountCompany')}
                    </label>
                    <input
                        type="text"
                        onChange={handleFormChange}
                        placeholder={t('account.AccountCompanyName')}
                        name="company"
                        value={company}
                    />
                  </div>
                  <div className="ip">
                    <label>
                    {t('account.AccountPass')}<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                          type={`${showPass ? "text" : "password"}`}
                          className="input-form password-input"
                          onChange={handleFormChange}
                          placeholder={t('account.AccountPass')}
                          required
                          name="password"
                          value={password}
                          
                      />
                      <Link
                          className={`password-addon ${
                              showPass ? "icon-eye" : "icon-eye-off"
                          }`}
                          onClick={() => setShowPass(!showPass)}

                      />

                    </div>
                    {/*{validate ?
                        <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.password}</span> : ''}*/}
                      {
                        checkPassword ?
                          <PasswordChecklist
                          rules={["minLength","specialChar","number","capital","match", "noSpaces", "capitalAndLowercase","notEmpty"]}
                          minLength={10}
                          value={password}
                          valueAgain={password_confirmation}
                          messages={{
                            minLength: t('checkpass.minLength'),
                            specialChar: t('checkpass.specialChar'),
                            number: t('checkpass.number'),
                            capital: t('checkpass.capital'),
                            match: t('checkpass.match'),
                            noSpaces: t('checkpass.noSpaces'),
                            capitalAndLowercase: t('checkpass.capitalAndLowercase'),
                            notEmpty: t('checkpass.notEmpty'),
                          }}
                          onChange={(isValid)=> {setIsValid(isValid); console.log(isValid)}}
                          />
                        :
                        ''
                      }  
                       
                  </div>
                  <div className="ip">
                    <label>
                    {t('account.AccountConfirmPass')}<span>*</span>
                    </label>
                    <div className="inputs-group auth-pass-inputgroup">
                      <input
                          type={`${showPass2 ? "text" : "password"}`}
                          className="input-form password-input"
                          name="password_confirmation"
                          value={password_confirmation}
                          onChange={handleFormChange}
                          placeholder={t('account.AccountPass')}
                          required
                      />
                      <Link
                          className={`password-addon ${
                              showPass2 ? "icon-eye" : "icon-eye-off"
                          }`}
                          onClick={() => setShowPass2(!showPass2)}
                      />
                    </div>
                    {
                        checkPasswordConfirmation ?
                          <PasswordChecklist
                          rules={["minLength","specialChar","number","capital","match", "noSpaces", "capitalAndLowercase","notEmpty"]}
                          minLength={10}
                          value={password}
                          valueAgain={password_confirmation}
                          messages={{
                            minLength: t('checkpass.minLength'),
                            specialChar: t('checkpass.specialChar'),
                            number: t('checkpass.number'),
                            capital: t('checkpass.capital'),
                            match: t('checkpass.match'),
                            noSpaces: t('checkpass.noSpaces'),
                            capitalAndLowercase: t('checkpass.capitalAndLowercase'),
                            notEmpty: t('checkpass.notEmpty'),
                          }}
                          onChange={(isValid)=> {setIsValid(isValid); console.log(isValid)}}
                          />
                        :
                        ''
                      }                    
                    {/*{validate ? <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.password_confirmation}</span>
                        : ''}*/}
                  </div>
                  <div className="group-ant-choice st">
                    <div className="sub-ip">
                      <input type="checkbox"
                             name="checkbox_terms"
                             checked={is_checked}
                             onClick={handleFormChange}
                      />{t('account.AccountAccept')}
                      <Link to="/termsofuse"> {t('account.AccountTermOfUse')}</Link>
                    </div>
                    {validate ? <span style={{
                          color: "red",
                          fontWeight: "italic"
                        }}>{errors.is_checked}</span>
                        : ''}
                  </div>
                  <div className="group-ant-choice st">
                  <button type="button"
                          disabled={!isValid}
                          onClick={register}>{t('account.AccountCreate')}</button>&nbsp;&nbsp;
                          {isLoading ?
                  <ReactLoading type={"spin"} color="red" height={50}
                                                                          width={(50)}/> : null}
                                                                          </div>
                  <div className="sign-up">
                  {t('account.AccountExist')}
                    <Link to="/login">&nbsp; {t('account.AccountConnexion')}</Link>
                  </div>
                </form>
              </TabPanel>
            </div>
          </Tabs>
        </div>
      </div>
    </section>
  );
}

export default SignUp;
