
export const EMAIL_REGEX = /^\w+([\.-]?\S+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

// This regex is to control the strength of a password
export const PASSWORD_REGEX =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!\"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{10,}$/


export const encodeUrl = (url) => {
    return url.replace(/ /g, "%20");
};

export const testUrlValid =(url)=>{
    return url !== "null" && url !== null;
}
