import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getLastUrl,
  getUser,
  isAuthenticated,
  removeLastUrl,
  setLastUrl,
  signIn,
} from "../../auth/Auth";
import { useTranslation } from "react-i18next";

import img from "../../assets/images/review/google.png"; 
import { connection, generateOtp } from "../../services/AuthServices";
import { testUrlValid } from "../../Constants";
import ReactLoading from "react-loading";
function SignIn() {
  const [showPass, setShowPass] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("success");
  const [openAlert, setOpenAlert] = useState(false);
  const [error, setError] = useState("")
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  let lastUrl = null;
  const navigates = useNavigate();

  const loginUser = async (e) => {

    const notify= (message, origin) => toast[origin](message);
    if (email.trim() === '' || password.trim() === '') {
      //setError('Email and password are required.');
      notify(t('Notifications.error.errorInfoRequired'), 'error')
    }else{
      setIsLoading(true);
      try {
        e.preventDefault();

        const data = {
          email: email,
          password: password,
          remember: true,
        };
        lastUrl = getLastUrl();
        console.log(lastUrl);

        // setLoading(true);
        const response = await connection(data);
        setError("")

        //handeling the response status and message

        localStorage.setItem("temp_email", email);

        if (response.data.data.user.is_first_connection === 1) {
          navigates("/reset-password");
          return;
        }
        //storing data in the localstorage
        signIn(response.data);
        let cond = testUrlValid(lastUrl);
        const res = await generateOtp(getUser().id);
        console.log(res);
        const otp = res.data.otp.otp;
        if (cond == true) {
          console.log("Url validated");
          if (otp) {
            setAlertMessage(
              "Success! Your OTP has been sent to your email. Please check your email"
            );
            window.location.replace(lastUrl + "?otp=" + otp);
            //return;
          }
        }
      } catch (error) {
        console.log(error);

        if (error.status == 400){
          notify(error.data.message, 'error');

        }
        if (error.status === 403) {
          let email = error.data.message;
          console.log(email);
        } else {
          // showAlert("error", error.data.message)
          //console.log(error.data.message);
        }
      }
      setIsLoading(false);
    } 

  };

  useEffect(() => {
    const handleRedirect = async () => {
      lastUrl = getLastUrl();
      console.log(lastUrl);


      let cond = testUrlValid(lastUrl);

      if (cond) {
        //setLastUrl(lastUrl);
        if (isAuthenticated()) {
          //return
          const response = await generateOtp(getUser().id);
          const otp = response.data.otp.otp;
          if (otp) {
            window.location.replace(lastUrl + "?otp=" + otp);
          }
        }
      }
    };
    handleRedirect();
  }, []);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };



  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
        <div><ToastContainer /></div>
          <div className="wd-form-login">
            <img
              src="media-intelligence-icon.png"
              className="mb-4"
              alt="logo"
            />

            <h4>{t('signin.identify')}</h4>
            <form action="/">
              <div className="ip">
                <label>
                {t('signin.email')}<span>*</span>
                </label>
                <input
                  type="text"
                  //defaultValue="Tony Nguyen"
                  placeholder={t('signin.emailPlaceholder')}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="ip">
                <label>
                {t('signin.passwordPlaceholder')}<span>*</span>
                </label>
                <div className="inputs-group auth-pass-inputgroup">
                  <input
                    type={showPass ? "text" : "password"}
                    className="input-form password-input"
                    placeholder={t('signin.passwordPlaceholder')}
                    id="password-input"
                    required
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Link
                    className={`password-addon ${
                      showPass ? "icon-eye" : "icon-eye-off"
                    }`}
                    id="password-addon"
                    onClick={() => setShowPass(!showPass)}
                  />
                </div>
              </div>
              <div className="group-ant-choice">
                <div className="sub-ip">
                  <input type="checkbox" />
                  {t('signin.rememberMe')}
                </div>
                <Link to="#" className="forgot">
                {t('signin.passwordForgot')}
                </Link>
              </div>
              <div className="group-ant-choice st">
                <button type="submit" onClick={loginUser}>
                {t('signin.connexion')}
                </button>
                {isLoading ? (
                  <ReactLoading
                    type={"spin"}
                    color="red"
                    height={50}
                    width={50}
                  />
                ) : null}
              </div>
              <p className="line-ip">
                <span>{t('signin.connexionSuggest')}</span>
              </p>
              <Link to={`${process.env.REACT_APP_BACKEND_SERVICE}/auth/redirect`} className="btn-social">
                <img src={img} alt="images" />{t('signin.googleConnect')}
              </Link>            
              <div className="sign-up">
              {t('signin.NoAccount')} <Link to="/register">{t('signin.register')}</Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
