import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {getLastUrl, getUser, isAuthenticated, logout, removeLastUrl, setLastUrl, signIn} from "../../auth/Auth";
import {connection, generateOtp} from "../../services/AuthServices";

//import {connection, generateOtp} from "../../Services/AuthServices";
import Preloader from "../preloader";
function SignOut() {

  useEffect(() => {

    const handleRedirect = async () => {

      logout();
      window.location.replace(process.env.REACT_APP_CLIENT_SERVICE);

    }
    handleRedirect();

  }, []);


  return (
    <>
        <Preloader />
    </>
  );
}

export default SignOut;
